import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import './assets/css/base.css'
import './assets/css/index.css'
import './assets/css/ipad.scss'//ipad适配css
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import axios from 'axios'
Vue.prototype.axios = axios;
import device from 'current-device'
import * as echarts from 'echarts';
Vue.prototype.$echarts = echarts
import global_ from './views/global.vue'
Vue.prototype.GLOBAL = global_
Vue.use(ElementUI);
Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
