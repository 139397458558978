<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
export default {
  name: "App",
  data() {
    return {};
  },
  mounted() {
     if (device.ipad()) {
      document.body.removeAttribute('class', 'bodyPc')
      document.getElementsByTagName('body')[0].className = 'bodyIpad'
      this.GLOBAL.isIpad = true;
    } else {
     this.GLOBAL.isIpad = false;
      document.body.removeAttribute('class', 'bodyMobile')
      document.getElementsByTagName('body')[0].className = 'bodyPc'
    }
  },
};
</script>
