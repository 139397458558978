import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

const routes = [
    {
        path: '/',
        redirect: '/login',
        component: () => import('../views/login'),
    },
    {
        path: '/login',
        name:'login',
        component: () => import('../views/login'),
    },
    {
        path: '/home',
        name:'home',
        component: () => import('../views/home'),
    },
   
    {
        path: '/index',
    // redirect: '/login',
    component: () => import('../views/index'),
        children: [
          
    {
      path: '/farmingPicture',
      name: 'farmingPicture',
      component: () => import('../views/FarmingPicture.vue'),
    },
    {
      path: '/FarmRemotesensing',
      name: 'FarmRemotesensing',
      component: () => import('../views/FarmingRemotesensing.vue'),
    },
    {
      path: '/FarmTransactions',
      name: 'FarmTransactions',
      component: () => import('../views/FarmTransactions.vue'),
    },
    {
      path: '/farmingPolicy',
      name: 'farmingPolicy',
      component: () => import('../views/FarmPolicy.vue'),
    },
    {
      path: '/farmApproval',
      name: 'farmApproval',
      component: () => import('../views/FarmApproval.vue'),
    },
    {
      path: '/farmApprovalOperate',
      name: 'farmApprovalOperate',
      component: () => import('../views/FarmApprovalOperate.vue'),
    },
  ]
    },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});
router.beforeEach((to, from, next) => {
    if (to.path == '/login'||to.path=='/home') {
        next();
    } else {
        var token = sessionStorage.getItem('selfToken');
        if (token) {
            next();
        }
        else {
            window.open("http://manage.xingnongbao.net/", "_self");
            next()
        }
    }

  })
export default router;
