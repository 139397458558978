import Vue from 'vue';
import Vuex from 'vuex';
import {
  service
} from "@/assets/js/request";
Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    // approvalForm: {
    //   page: 1,
    //   limit: 10,
    // },
    approvalList: 0
  },
  getters: {},
  mutations: {
      setApprovalList(state) {
           //测试如果用户刷新清空则回到登录页面
        if (JSON.stringify(localStorage) == "{}") {
            this.$router.push({
            path: "/login",
            });
        }
        //初始化获取用户信息
        var userInfo = JSON.parse(localStorage.getItem("userInfo"));
          var deptId = userInfo.deptId;
          var userRole = userInfo.userRole;
          if (userRole == '连') {
              var status = '0';
          }else if (userRole == '团') {
            var status = '1';
        }else if (userRole == '师') {
            var status = '2';
        }
        var parm = {deptId:deptId,status:status};
          service.get(`/dimErrorProblem/getFindData`, { params: parm }).then((res) => {
              if ('obj' in res) {
                state.approvalList = res.obj.length;
              }
        });
    },
    setApprovalForm(state, data) {
      state.approvalForm = data;
    },
  },
  actions: {},
  modules: {},
});
